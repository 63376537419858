<template>
  <div>
    <!-- Your Accounts / Company Accounts Tabs -->
    <b-tabs
      id="tour-wallets"
      v-model="tabIndex"
      content-class="mt-3"
      class="position-relative"
      pills
    >
      <b-tab :title="$t('Your Accounts')">
        <wallet-family />
      </b-tab>
      <b-tab v-if="canEditCompany || isCompanyAccountant">
        <template #title>
          <i class="fa fa-building" />
          {{ company.name }}{{ $t('\'s Accounts') }}
        </template>
        <wallet-family
          user-type="company"
          :company="company"
        />
      </b-tab>

      <!-- Manage Permissions Button -->
      <template #tabs-end>
        <div class="position-absolute right-0 d-flex">
          <!-- Fund Load -->
          <!-- <b-button
            v-if="tabIndex === 1 && canViewCompanyAccount"
            v-b-modal.stripe-load-fund-modal
            variant="primary"
            class="mr-1"
          >
            <feather-icon icon="DollarSignIcon" />
            Load Fund
          </b-button> -->

          <!-- Scheduled Transfers -->
          <b-button
            v-if="canEditCompany"
            id="tour-scheduled-transfers"
            v-b-tooltip.hover="'View Scheduled Transfers'"
            class="position-relative"
            variant="primary"
            @click="viewScheduledTransfers"
          >
            <i class="fa fa-clock-rotate-left position-absolute clock-icon" />
            <i class="fa fa-calendar-days position-relative calendar-icon" />
          </b-button>
        </div>
      </template>
    </b-tabs>

    <!-- Fund Load for Company Account -->
    <!-- <stripe-fund-load /> -->
  </div>
</template>

<script>
import { BTabs, BTab, BButton } from 'bootstrap-vue'
import EventBus from '@/event-bus'
import { checkPermission } from '@/utils/permissions'
// import StripeFundLoad from './components/WalletActions/LoadFundFromStripe.vue'
import { mapActions, mapGetters } from 'vuex'
import WalletFamily from './components/WalletFamily.vue'
import { setComponentReady } from '../tour/tour'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    // StripeFundLoad,
    WalletFamily,
  },
  data() {
    return {
      company: this.$store.state.project.company,
      tabIndex: 0,
      permissions: ['company.edit', 'accounts.company'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      walletCount: 1,
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany', 'isCompanyAccountant']),
  },
  mounted() {
    this.checkUserPermission()
    EventBus.$on('permission-updated', permission => {
      if (this.permissions.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermission(), 2000)
      }
    })
    if (this.$route.params.tab && this.$route.params.tab === 'Company Accounts') {
      setTimeout(() => { this.tabIndex = 1 }, 800)
    }
    setComponentReady()
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    checkUserPermission() {
      if (!this.canEditCompany || !this.isCompanyAccountant || this.needPermissionCheck) {
        checkPermission(this.permissions, permissions => {
          const canEditCompany = permissions['company.edit'] === true
          const isCompanyAccountant = permissions['accounts.company'] === true

          const payload = [
            { key: 'setCompanyEditPermission', value: canEditCompany },
            { key: 'setAccountantPermission', value: isCompanyAccountant },
          ]

          this.setPermissions(payload)
          this.needPermissionCheck = false
        })
      }
    },
    async viewScheduledTransfers() {
      this.$router.push({ name: 'scheduled-transfers', params: { userType: this.tabIndex ? 'company' : 'users' } })
    },
  },
}
</script>

<style scoped>
.clock-icon {
  font-size: 12px;
  bottom: 6px;
  right: 11px;
}
.calendar-icon {
  right: 4px;
}
</style>
