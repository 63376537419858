<template>
  <b-modal
    id="add-new-wallet-modal"
    :title="$t('Add New Open Account')"
    no-close-on-backdrop
    :ok-title="form.currentStep === 0 ? $t('Next') : $t('Submit')"
    :cancel-title="form.currentStep === 0 || (isCompanyDefaultWallet && form.currentStep === 1) ? $t('Cancel') : $t('Back')"
    :busy="isLoading"
    :ok-disabled="form.shareType === 'BANKWALLET' && !form.informationId"
    @ok="next"
    @cancel="prev"
    @hidden="form.description = ''"
  >
    <b-overlay :show="isLoading">
      <div class="p-1">
        <validation-observer ref="walletForm">
          <wallet-config
            :form-data="form"
            family-type="OPENWALLET"
            :index="0"
            :user-type="userType"
            :used-banks="usedBanks"
          />
        </validation-observer>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { BModal, BOverlay } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationObserver } from 'vee-validate'
import WalletConfig from './WalletConfig.vue'

export default {
  components: {
    WalletConfig,
    BModal,
    BOverlay,
    ValidationObserver,
  },
  props: {
    userType: {
      type: String,
      default: () => 'users',
    },
    usedBanks: {
      type: Array,
      default: () => [],
    },
    wallet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        description: '',
        shareType: 'INTERNALWALLET',
        billingInfo: null,
        currentStep: 0,
        creating: false,
        informationId: null,
      },
      isLoading: false,
      company: this.$store.state.project.selectedCompany,
    }
  },
  computed: {
    isCompanyDefaultWallet() {
      return this.userType === 'company' && this.wallet.isDefault
    },
  },
  watch: {
    isCompanyDefaultWallet: {
      handler(val) {
        if (val) {
          this.form.shareType = 'PAYROLLWALLET'
          this.form.currentStep = 1
        } else {
          this.form.shareType = 'INTERNALWALLET'
          this.form.currentStep = 0
        }
      },
      immediate: true,
    },
  },
  methods: {
    next(bvModal) {
      bvModal.preventDefault()
      this.$refs.walletForm.validate().then(success => {
        if (success) {
          if (this.form.currentStep === 1) this.addNewWallet()
          else this.form.currentStep += 1
        }
      })
    },
    prev(bvModal) {
      if (this.form.currentStep === 0 || (this.isCompanyDefaultWallet && this.form.currentStep === 1)) this.$bvModal.hide('add-new-wallet-modal')
      else {
        bvModal.preventDefault()
        this.form.currentStep -= 1
      }
    },
    addNewWallet() {
      this.isLoading = true
      const {
        shareType, description, informationId, informationType,
      } = this.form
      const params = {
        walletUid: this.wallet.uuid,
        input: [{
          shareType,
          sharePercent: 100,
          description,
          ...(shareType === 'BANKWALLET' ? {
            informationId,
            informationType,
          } : {}),
        }],
      }
      let api = 'addNewWalletShares'

      if (this.userType === 'company') {
        api = 'addNewCompanyWalletShares'
        params.companyUid = this.company
      }
      useApollo[this.userType][api](params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.$emit('wallet-added', this.wallet)
        this.$bvModal.hide('add-new-wallet-modal')
        this.isLoading = false
      })
    },
  },
}
</script>
